import { authRoles } from "app/auth";

const config = {
  items: [
    {
      name: "Stats Overview",
      url: "/overview",
      icon: "icon-speedometer",
      auth: authRoles.admin,
    },
    {
      name: "Users",
      icon: "icon-people",
      auth: [...authRoles.admin, ...authRoles.usersViewer],
      children: [
        {
          name: "All Users",
          url: "/users",
          icon: "icon-people",
        },
        {
          name: "Banned Users",
          url: "/bannedusers",
          icon: "icon-lock",
        },
        {
          name: "Users score",
          url: "/users-score",
          icon: "icon-graph",
        },
      ],
    },
    {
      name: "Rewards",

      icon: "icon-basket",
      auth: [...authRoles.admin, ...authRoles.rewardsViewer],
      children: [
        {
          name: "Requested Rewards",
          url: "/rewards/requested",
          icon: "icon-check",
        },
        {
          name: "All Rewards",
          url: "/rewards/all",
          icon: "icon-layers",
        },
        {
          name: "Manage Rewards",
          url: "/rewards/manage",
          icon: "icon-note",
        },
      ],
    },
    {
      name: "Revenues",
      url: "/revenues",
      icon: "icon-chart",
      auth: [...authRoles.admin, ...authRoles.revenuesViewer],
    },
    {
      name: "Profit",
      icon: "fa fa-money",
      auth: [...authRoles.profitViewer],
      children: [
        {
          name: "Profit",
          url: "/profit",
          icon: "fa fa-money",
        },
        {
          name: "Average Units",
          url: "/profit/average-units",
          icon: "fa fa-money",
        },
      ],
    },
    {
      name: "Games",
      url: "/games",
      icon: "icon-game-controller",
      auth: [...authRoles.admin, ...authRoles.gamesViewer],
    },
    {
      name: "Boosted Games",
      url: "/boostedgames",
      icon: "fa fa-line-chart",
      auth: [...authRoles.admin, ...authRoles.gamesViewer],
    },
    {
      name: "Campaigns",
      icon: "fa fa-play",
      auth: [...authRoles.admin, ...authRoles.gamesViewer],
      children: [
        {
          name: "All Campaigns",
          url: "/campaigns",
          icon: "fa fa-play",
        },
        {
          name: "Email Reports",
          url: "/campaignemailreports",
          icon: "fa fa-envelope",
        },
      ],
    },
    {
      name: "Config",
      icon: "fa fa-cogs",
      auth: [...authRoles.admin, ...authRoles.bonusViewer],
      children: [
        {
          name: "Parameters",
          url: "/bonus/parameters",
          icon: "fa fa-tasks",
        },
        {
          name: "Seasonal Boost",
          url: "/bonus/seasonalboost",
          icon: "fa fa-calendar-check-o",
        },
        {
          name: "Source of Traffic",
          url: "/bonus/trafficsources",
          icon: "fa fa-magnet",
        },
        {
          name: "App Settings",
          url: "/bonus/app-settings",
          icon: "fa fa-list-alt",
        },
        /*{
          name: "Perk Avatars",
          url: "/config/perk-avatars",
          icon: "fa fa-rocket",
        },*/
      ],
    },
    {
      name: "Contests",
      url: "/contests",
      icon: "icon-puzzle",
      auth: [...authRoles.admin, ...authRoles.contestsViewer],
    },
    {
      name: "DailyQuests",
      icon: "fa fa-question-circle",
      auth: [...authRoles.admin, ...authRoles.dailyQuestsViewer],
      children: [
        {
          name: "DailyQuests",
          icon: "fa fa-question-circle",
          auth: [...authRoles.admin, ...authRoles.dailyQuestsViewer],
          url: "/dailyquests",
        },
        {
          name: "DailyQuests Stats",
          icon: "fa fa-question-circle",
          auth: [...authRoles.admin, ...authRoles.dailyQuestsViewer],
          url: "/dailyquestsStats",
        },
      ],
    },
    {
      name: "Raffles",
      url: "/raffles",
      icon: "fa fa-ticket",
      auth: [...authRoles.admin, ...authRoles.contestsViewer],
    },
    {
      name: "General Pages",
      url: "/generalpages",
      icon: "icon-globe",
      auth: [...authRoles.admin, ...authRoles.generalViewer],
      children: [
        {
          name: "Privacy Policy",
          url: "/generalpages/privacy",
          icon: "icon-doc",
          auth: [...authRoles.admin, ...authRoles.generalViewer],
        },
        {
          name: "Terms & Conditions",
          url: "/generalpages/terms",
          icon: "icon-doc",
          auth: [...authRoles.admin, ...authRoles.generalViewer],
        },
        {
          name: "FAQ",
          url: "/generalpages/faq",
          icon: "icon-doc",
          auth: [...authRoles.admin, ...authRoles.generalViewer],
        },
        {
          name: "How it works",
          url: "/generalpages/howitworks",
          icon: "icon-doc",
          auth: [...authRoles.admin, ...authRoles.generalViewer],
        },
      ],
    },
    {
      name: "Notifications",
      url: "/notifications",
      icon: "icon-paper-plane",
      auth: [...authRoles.admin, ...authRoles.notificationsViewer],
    },
    {
      name: "Permissions",
      url: "/permissions",
      icon: "icon-key",
      auth: authRoles.admin,
    },
    {
      name: "Advertisers",
      url: "/advertisers",
      icon: "icon-briefcase",
      auth: authRoles.admin,
    },
    {
      name: "Boosts & Bonuses",
      // url: "/gameboosts-bonuses",
      icon: "icon-briefcase",
      auth: authRoles.admin,
      children: [
        {
          name: "Extra Game Bonus",
          url: "/extragamebonus",
          icon: "icon-briefcase",
          auth: authRoles.admin,
        },
        {
          name: "Game Boost",
          url: "/gameboost",
          icon: "icon-briefcase",
          auth: authRoles.admin,
        },
      ],
    },
    {
      name: "Quotes",
      url: "/quotes",
      icon: "icon-book-open",
      auth: authRoles.admin,
    },
    {
      name: "Fraud",
      url: "/fraud",
      icon: "icon-shield",
      auth: [...authRoles.admin, ...authRoles.fraudpanelViewer],
      children: [
        {
          name: "Countries",
          url: "/fraudpanel",
          icon: "icon-shield",
          auth: [...authRoles.admin, ...authRoles.fraudpanelViewer],
        },
        {
          name: "ISP",
          url: "/isp",
          icon: "icon-shield",
          auth: [...authRoles.admin, ...authRoles.fraudpanelViewer],
        },
        {
          name: "App Whitelist",
          url: "/appwhitelist",
          icon: "icon-shield",
          auth: [...authRoles.admin, ...authRoles.fraudpanelViewer],
        },
        {
          name: "Auto Clickers",
          url: "/autoclickers",
          icon: "icon-shield",
          auth: [...authRoles.admin, ...authRoles.fraudpanelViewer],
        },
        {
          name: "Marked",
          url: "/marked",
          icon: "icon-shield",
          auth: [...authRoles.admin, ...authRoles.fraudpanelViewer],
        },
        {
          name: "Carriers",
          url: "/carriers",
          icon: "icon-shield",
          auth: [...authRoles.admin, ...authRoles.fraudpanelViewer],
        },
        {
          name: "Fraudalytics",
          url: "/fraudalytics",
          icon: "icon-shield",
          auth: [...authRoles.admin, ...authRoles.fraudalyticsViewer],
        },
      ],
    },
    {
      name: "Breakdown Stats",
      url: "/breakdownstats",
      icon: "icon-chart",
      auth: [...authRoles.admin, ...authRoles.breakdownStatsViewer],
    },
    {
      name: "Game Stats",
      url: "/gamestats",
      icon: "icon-chart",
      auth: authRoles.admin,
    },
    {
      name: "Uplift Games Stats",
      url: "/upliftgamesstats",
      icon: "icon-chart",
      auth: [...authRoles.admin, ...authRoles.breakdownStatsViewer],
    },
    {
      name: "Install",
      url: "/install",
      icon: "icon-game-controller",
      auth: [...authRoles.admin, ...authRoles.installViewer],
    },
    {
      name: "Referals",
      url: "/referals",
      icon: "icon-people",
      auth: [...authRoles.admin, ...authRoles.referalsViewer],
    },
    {
      name: "Content Reward",
      url: "/contentreward",
      icon: "icon-briefcase",
      auth: [...authRoles.admin, ...authRoles.contentRewardViewer],
    },
    {
      name: "Db Calls",
      url: "/dbcalls",
      icon: "icon-globe",
      auth: [...authRoles.admin, ...authRoles.generalViewer],
      children: [
        {
          name: "User Agents",
          url: "/dbcalls/useragents",
          icon: "icon-doc",
          auth: [...authRoles.admin, ...authRoles.generalViewer],
        },
        {
          name: "Resolution",
          url: "/dbcalls/resolution",
          icon: "icon-doc",
          auth: [...authRoles.admin, ...authRoles.generalViewer],
        },
        {
          name: "IP",
          url: "/dbcalls/ip",
          icon: "icon-doc",
          auth: [...authRoles.admin, ...authRoles.generalViewer],
        },
      ],
    },
    {
      name: "GAID Block",
      url: "/gaid-block",
      icon: "icon-shield",
      auth: [...authRoles.admin, ...authRoles.gaidBlockViewer],
    },
    {
      name: "GAID User Info",
      url: "/gaid-user-info",
      icon: "icon-shield",
      auth: [...authRoles.admin, ...authRoles.gaidBlockViewer],
    },
    {
      name: "Game Whitelist Competitors",
      url: "/game-whitelist-competitors",
      icon: "icon-list",
      auth: [...authRoles.admin, ...authRoles.gaidBlockViewer],
    },
  ],
};

export default config;
